<template>
  <div class="c-red-decorate-title" @click="onClick">
    <p
      class="title"
      :style="{
        color: titleColor,
        ...cusStyle
      }"
    >
      {{ title }}
    </p>
    <img
      v-show="size === ''"
      class="decorate-img"
      :style="placement"
      src="@/assets/icon/highlight_1.png"
      ref="decoreateImg"
    />

    <img
      v-show="size === 'small'"
      :style="placement"
      class="decorate-img--normal"
      src="@/assets/icon/highlight_3.png"
    />
    <img
      v-show="size === 'middle'"
      :style="placement"
      class="decorate-img--normal"
      src="@/assets/icon/highlight_2.png"
    />
    <img
      v-show="size === 'large'"
      :style="placement"
      class="decorate-img--normal"
      src="@/assets/icon/highlight_1.png"
    />
  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  // watch,
} from 'vue';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'RedDecorateTitle',
  props: {
    title: {
      type: String,
    },
    titleColor: {
      type: String,
      default: '#000000',
    },
    size: {
      type: String,
      default: '',
    },
    cusStyle: {
      type: Object,
      default: () => ({}),
    },
    placement: {
      type: Object,
      default: () => ({
        bottom: '5px',
        right: '-10px',
      }),
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const decoreateImg = ref(null);
    const siteStore = useSiteStore();
    const appRoot = computed(() => siteStore.appRoot);
    const placement = computed(() => placement);

    const handleScroll = () => {
      const windowTopOffset = decoreateImg.value.getBoundingClientRect().top;
      const threshold = document.body.clientHeight * 0.9; // 距離底部 10% （1-0.9）時出現

      if (threshold > windowTopOffset) {
        decoreateImg.value.classList.add('animate__animated', 'animate__fadeInRight');
        appRoot.value.removeEventListener('scroll', handleScroll);
      }
    };

    const onClick = (e) => {
      emit('click', e);
    };

    onMounted(() => {
      if (props.size === '') {
        appRoot.value.addEventListener('scroll', handleScroll);
      }
    });

    return {
      decoreateImg,
      onClick,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-red-decorate-title {
  display: inline-block;
  position: relative;

  // overflow: hidden;
}
.title {
  @include font-style($c-black, 24, bold, 1.2px, 32px);
  position: relative;
  z-index: 10;
}

.decorate-img {
  @include position(br, 5px, -10px);
  opacity: 0;
  animation-fill-mode: forwards;
}

.decorate-img--normal {
  @include position(br, 5px, -10px);
}

@media screen and (min-width: $tablet) {
  .c-red-decorate-title {
    display: inline-block;
    position: relative;

    // overflow: hidden;
  }
  .title {
    @include font-style($c-black, 35, bold, 1.75px);
    position: relative;
    z-index: 10;
  }

  .decorate-img {
    @include position(br, 5px, -10px);
    opacity: 0;
    animation-fill-mode: forwards;
  }

  .decorate-img--normal {
    @include position(br, 5px, -10px);
  }
}
</style>
