<template>
  <div class="c-first-view">
    <div class="c-first-view__content">
      <div class="g-content">
        <h2 class="about-title">關於我們</h2>

        <div class="about-wrap">
          <div class="about-desc">
            <p class="about-desc__en">
              <span class="about-desc__en__text">WE ARE NOW THE</span>
              <span class="about-desc__en__text">LARGEST COPYRIGHTED</span>
              <span class="about-desc__en__text">MUSIC AGENT IN TAIWAN.</span>
            </p>
            <div class="about-desc__ch">
              <p>音韶為台灣最大版權音樂代理商，</p>
              <p>超過140萬首音樂提供業界多樣化選擇，</p>
              <p>齊全、豐富的音樂，可滿足各類需求。</p>
            </div>
          </div>
        </div>

        <img class="about-img" src="@/assets/about/img_kv.png" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FirstView',
});
</script>
<style lang="scss" scoped>
.c-first-view {
  padding-top: 115px;
  padding-bottom: 65px;
  background-color: $c-black;
  background-image: url('~@/assets/about/img_deco.png');
  background-size: 255px;
  background-repeat: no-repeat;
  background-position: bottom left;

  &__content {
    position: relative;
  }
}

.g-content {position: relative;}

.about-title {
  @include font-style($c-white, 24, 800, 1.2px, 33px);
}

.about-wrap {
  // width: 100%;
  // background-image: url('~@/assets/about/img_kv.png');
  // background-size: 780px;
  // background-repeat: no-repeat;
  // background-position: center right;
  // margin-top: 23px;

  @include flex();
  // height: 520px;
  position: relative;
  z-index: 10;
}

.about-desc {
  align-self: flex-end;
  // margin-bottom: 77px;
  margin-top: 82px;

  &__en {
    &__text {
      display: inline-block;
      width: 100%;
      @include font-style($c-white, 24, 800, 0, 30px);
    }
  }

  &__ch {
    width: 336px;
    @include font-style($c-white, 16, normal, 0.8px, 24px);
    margin-top: 24px;
  }
}

.about-img {
  @include position(tr, 0, 0);
  width: 68%;
  max-width: 470px;
}

@media screen and (min-width: $mobile) {
  .about-img {
    max-width: 500px;
  }
}

// @media screen and (min-width: $portrait) {
//   .c-first-view {
//     background-size: 589px;
//   }

// }

@media screen and (min-width: $tablet) {
  .c-first-view {
    padding: 163px 0 150px;
    background-color: $c-black;
    background-image: url('~@/assets/about/img_deco.png');
    background-size: 589px;
    background-repeat: no-repeat;
    background-position: bottom left;
  }

  .about-title {
    @include font-style($c-white, 45, 800, 2.25px);
  }

  // .about-wrap {
  //   width: 100%;
  //   background-image: url('~@/assets/about/img_kv.png');
  //   background-size: 780px;
  //   background-repeat: no-repeat;
  //   background-position: center right;
  //   margin-top: 23px;
  //   height: 520px;
  //   @include flex();
  // }

  .about-desc {
    align-self: flex-end;
    margin-top: 100px;

    &__en {
      &__text {
        display: inline-block;
        width: 100%;
        @include font-style($c-white, 50, 800, 2.5px);
      }
    }

    &__ch {
      width: 336px;
      @include font-style($c-white, 18, normal, 0.9px);
      margin-top: 24px;
    }
  }

  .about-img {
    top: 80px;
    right: 0px;
    max-width: 630px;
  }
}

@media screen and (min-width: $sm-pc) {
  .about-desc {
    margin-top: 150px;
  }

  .about-img {
    top: 80px;
    max-width: 780px;
  }
}
</style>
