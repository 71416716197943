<template>
  <div class="v-about-us">
    <div class="v-about-us">
    <first-view />
    <agent />
    <experience />
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import FirstView from '@/components/AboutUs/FirstView.vue';
import Agent from '@/components/AboutUs/Agent.vue';
import Experience from '@/components/AboutUs/Experience.vue';

export default defineComponent({
  name: 'AboutUs',
  components: {
    FirstView,
    Agent,
    Experience,
  },
});
</script>
<style lang="scss" scoped>
.v-about-us {
  overflow-x: hidden;
}

</style>
