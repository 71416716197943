import serviceSearch from '@/assets/about/service_1.svg';
import serviceExclusive from '@/assets/about/service_2.svg';
import serviceCommercial from '@/assets/about/service_3.svg';

export default [
  {
    icon: serviceSearch,
    label: '線上音樂搜尋',
  },
  {
    icon: serviceExclusive,
    label: '獨家音樂授權',
  },
  {
    icon: serviceCommercial,
    label: '音樂代理洽談',
  },

];
