<template>
  <div class="c-agent">
    <div class="title-wrap">
      <div class="agent-wrap g-content">
        <red-decorate-title
          title="台灣最大版權音樂代理商"
          size="middle"
          :placement="{
            bottom: '0px',
            right: '-5px',
          }"
        />

        <p class="agent-desc">
          音韶文化於民國86年成立，初期以代理版權音樂為主要業務，是目前全世界最大版權公司Universal Publishing Production
          Music 台灣地區獨家代理商，並代理Warner Chappell Music各品牌版權音樂及全世界知名廠牌版權音樂，如
          Amphibiouszoo、Onetrackaday、Bing Bang Boom、Cézame Music Agency、Drama King、The Buzz Music Library...等。
        </p>
      </div>

      <div class="ribbon">

        <!-- tablet以下 -->
        <div class="ribbon__our-service">
          <img class="ribbon__our-service__text" src="@/assets/about/img_OUR.png" />
          <img class="ribbon__our-service__text" src="@/assets/about/img_SERVICE.png" />
        </div>

        <!-- tablet(含)以上 -->
        <img class="ribbon__our" src="@/assets/about/img_OUR.png" />
        <img class="ribbon__service" src="@/assets/about/img_SERVICE.png" />

        <div class="g-content">
          <div class="service-wrap">
            <div class="service-wrap__content">
              <ul class="service-list">
                <li class="service-list__item" v-for="service of agentConfig" :key="service.label">
                  <div class="service-box">
                    <img class="service-box__icon" :src="service.icon" />
                    <p class="service-box__text">{{ service.label }}</p>
                  </div>
                </li>
              </ul>
              <div class="contact-us">
                <mi-button @click="toContactUsPage">CONTACT US</mi-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import RedDecorateTitle from '@/components/Global/RedDecorateTitle.vue';
import agentConfig from '@/config/agentConfig';
import MiButton from '@/components/Global/MiButton.vue';

export default defineComponent({
  name: 'Agent',
  components: {
    RedDecorateTitle,
    MiButton,
  },
  setup() {
    const router = useRouter();

    const toContactUsPage = () => {
      router.push({
        name: 'front-contact',
      });
    };
    return {
      agentConfig,
      toContactUsPage,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-agent {
  padding-top: 56px;
  text-align: justify;
  text-align-last: center;
  background-color: $c-assist1;
}

.agent-desc {
  margin-top: 40px;
  margin-bottom: 195px;
  @include font-style($c-black, 16, normal, 0px, 24px);
}
.ribbon {
  position: relative;
  height: 522px;
  @extend %bg-gradient-90deg;
  @include flex(center, flex-end);

  &__our {
    display: none;
  }

  &__service {
    display: none;
  }

  &__our-service {
    @include flex(center);
    @include position(tl, calc(100% - 70px), 50%);
    transform: translateX(-50%);

    &__text {
      height: 30px;

      & + & {
        margin-left: 15px;
      }
    }
  }
}

.service-wrap {
  position: relative;
  top: -100px;
  // position: absolute;
  // bottom: 50px;
  // left: 50%;
  // transform: translateX(-50%);
  width: 100%;
  height: 584px;
  background-color: $c-white;
  box-shadow: 0px 0px 10px #0000000d;
  border-radius: 10px;
  @include padding(40px);

  @include flex(center);

  &__content {
  }
}

.service-list {
  @include flex(space-between, center, column);

  &__item {
    & + & {
      margin-top: 43px;
    }
  }
}

.service-box {
  @include flex(center, center, column);

  &__icon {
    width: 70px;
    vertical-align: bottom;
  }

  &__text {
    margin-top: 26px;
    @include font-style($c-black, 20, bold, 1px);
  }
}

.contact-us {
  margin-top: 50px;
  text-align: center;
}
@media screen and (min-width: $portrait) {
  .agent-wrap {
    max-width: 90%;
  }

  .service-wrap {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 90%;
    height: 300px;
    background-color: $c-white;
    box-shadow: 0px 0px 10px #0000000d;
    border-radius: 10px;
    @include padding(60px 100px);
    @include flex(center);

    &__content {
      width: 100%;
    }
  }

  .service-list {
    @include flex(space-between);

    &__item {
      & + & {
        margin-top: 0px;
      }
    }
  }

  .service-box {
    @include flex(center, center, column);

    &__icon {
      width: 70px;
      vertical-align: bottom;
    }

    &__text {
      margin-top: 26px;
      @include font-style($c-black, 20, bold, 1px);
    }
  }

  .ribbon {
    position: relative;
    height: 285px;
    @extend %bg-gradient-90deg;

    &.g-content {
      width: 100%;
      max-width: 100%;
    }

    &__our-service {
      &__text {
        height: 45px;

        & + & {
          margin-left: 22px;
        }
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .c-agent {
    padding-top: 158px;
    text-align: justify;
    text-align-last: center;
  }

  .agent-wrap {
    max-width: 800px;
  }
  .service-wrap {
    max-width: 800px;
  }
  .agent-desc {
    margin-top: 40px;
    margin-bottom: 195px;
    @include font-style($c-black, 18, normal, 0px);
  }

  .contact-us {
    text-align: center;
  }

  .ribbon {
    &__our {
      display: inline-block;
      @include position(tl, 20px, -30px);
    }

    &__service {
      display: inline-block;
      @include position(br, 20px, -30px);
    }

    &__our-service {
      display: none;
    }
  }
}
</style>
