<template>
  <div class="c-experience">
    <div class="c-experience__content">
      <p class="experience-title">
        <span class="experience-title__text"> MUSIC IS AN EXPRESSION </span>
        <span class="experience-title__text"> OF THE PEOPLE. </span>
      </p>
      <!-- <p class="experience-desc">探索音樂，創造感動體驗</p> -->

      <div class="deco-wrap">
        <red-decorate-title
          title="探索音樂，創造感動體驗"
          size="middle"
          :cus-style="{
            'font-size': '20px',
          }"
          :placement="{
            bottom: '3px',
            right: '-5px',
          }"
        />
      </div>

      <div class="explore">
        <div class="brick-wrap">
          <ul class="explore-list">
            <li class="explore-list__item" v-for="item of experienceConfig" :key="item.label" @click="toExploreInfo(item)">
              <div class="explore-list__item__mask"></div>
              <span class="explore-list__item__label">{{ item.label }}</span>
              <img class="explore-list__item__img" :src="item.img" />
            </li>
          </ul>
        </div>
        <div class="btn-wrap">
          <mi-button @click="toExplore">EXPLORE</mi-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import experienceConfig from '@/config/experienceConfig';
import MiButton from '@/components/Global/MiButton.vue';
import RedDecorateTitle from '@/components/Global/RedDecorateTitle.vue';
// import { useRouter } from 'vue-router';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'Experience',
  components: { MiButton, RedDecorateTitle },
  setup() {
    const router = useRouter();
    const siteStore = useSiteStore();

    const toExplore = () => {
      router.push({
        name: 'explore-type',
        params: {
          type: 'themes',
        },
      });
    };

    const toExploreInfo = (item) => {
      siteStore.setBackgroundImage(item.img);

      router.push({
        name: 'explore-themes-info',
        params: {
          category: item.label,
        },
      });
    };

    return {
      experienceConfig,
      toExplore,
      toExploreInfo,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-experience {
  margin-top: 56px;
}

.experience-title {
  text-align: center;

  &__text {
    width: 100%;
    display: inline-block;
    @include font-style($c-black, 24, 800, 0px, 30px);
  }
}

.deco-wrap {
  @include flex(center);
  margin-top: 24px;
  margin-bottom: 158px;
}

.experience-desc {
  text-align: center;
  margin-top: 24px;
  @include font-style($c-black, 20, bold, 1.75px, 35px);
}

.explore {
  @include flex(center, flex-end);
  background-color: $c-black;
  height: 278px;
  position: relative;
}

.brick-wrap {
  @include position(tl, 0, 0);
  transform: translateY(-50%);
  z-index: 10;

  // relative;
  // z-index: 10;
  height: 192px;
  width: 100%;
  white-space: nowrap;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 0;
  }
}

.explore-list {
  // margin-top: 140px;
  position: relative;
  z-index: 10;

  &__item {
    display: inline-block;
    width: 168px;
    flex: none;
    position: relative;

    &:hover {
      .explore-list__item__mask {
        opacity: 0.3;
      }

      .explore-list__item__label {
        opacity: 1;
      }
    }

    &:nth-child(2),
    &:nth-child(4) {
      position: relative;
      top: 24px;
    }

    &__img {
      width: 100%;
      vertical-align: bottom;
    }

    &__mask {
      @include position(tl, 0, 0);
      width: 100%;
      height: 100%;
      opacity: 0;
      background: transparent linear-gradient(180deg, #12c5ff 0%, #8431ff 50%, #f8559e 100%) 0% 0% no-repeat padding-box;
      transition: 0.3s;
    }

    &__label {
      opacity: 0;
      @include position(center);
      @include font-style($c-white, 20, bold, 1px, 30px);
      @include font-style();
      transition: 0.3s;
    }
  }
}

.btn-wrap {
  margin-bottom: 84px;
}

@media screen and (min-width: $portrait) {
  .c-experience {
    margin-top: 160px;
  }

  .experience-title {
    text-align: center;

    &__text {
      width: 100%;
      display: inline-block;
      @include font-style($c-black, 35, 800, 1.75px, 45px);
    }
  }

  .deco-wrap {
    margin-bottom: 300px;
  }

  .brick-wrap {
    @include position(tl, 0, 0);
    transform: translateY(-80%);
    z-index: 10;
    height: 192px;
    width: 100%;
    white-space: nowrap;
    overflow: visible;
  }

  .explore-list {
    @include flex();
    position: relative;
    z-index: 10;
    cursor: pointer;

    &__item {
      flex: 1;
      position: relative;

      &:hover {
        .explore-list__item__mask {
          opacity: 0.3;
        }

        .explore-list__item__label {
          opacity: 1;
        }
      }

      &:nth-child(2),
      &:nth-child(4) {
        position: relative;
        top: 24px;
      }

      &__img {
        width: 100%;
        vertical-align: bottom;
      }

      &__mask {
        @include position(tl, 0, 0);
        width: 100%;
        height: 100%;
        opacity: 0;
        background: transparent linear-gradient(180deg, #12c5ff 0%, #8431ff 50%, #f8559e 100%) 0% 0% no-repeat
          padding-box;
        transition: 0.3s;
      }

      &__label {
        opacity: 0;
        @include position(center);
        @include font-style($c-white, 20, bold, 1px, 30px);
        transition: 0.3s;
      }
    }
  }
}

@media screen and (min-width: $tablet) {
  .experience-desc {
    text-align: center;
    margin-top: 24px;
    @include font-style($c-black, 20, bold, 1.75px, 35px);
  }

  .explore {
    @include flex(center, flex-end);
    background-color: $c-black;
    height: 350px;
    position: relative;
  }

  .btn-wrap {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: $sm-pc) {
  .explore {
    height: 500px;
  }

  .btn-wrap {
    margin-bottom: 155px;
  }
}
</style>
