import exp1 from '@/assets/about/img_1.png';
import exp2 from '@/assets/about/img_2.png';
import exp3 from '@/assets/about/img_3.png';
import exp4 from '@/assets/about/img_4.png';
import exp5 from '@/assets/about/img_5.png';

export default [
  {
    label: 'Mars',
    img: exp1,
    id: 731298332,
  },
  {
    label: 'Earth Day',
    img: exp2,
    id: 73129831256,
  },
  {
    label: 'Tropical Forest',
    img: exp3,
    id: 7332412983,
  },
  {
    label: 'Desert',
    img: exp4,
    id: 7317892983,
  },
  {
    label: 'Mountain',
    img: exp5,
    id: 731112983,
  },
];
